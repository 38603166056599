<template>
    <modal ref="modalAddSubactivity" :titulo="titulo" tamano="modal-lg" :adicional="textBtnAdicional" @adicional="add"> 
        <ValidationObserver ref="subactivityValidator">
            <div class="row m-3 f-15">
                <div class="col-8">
                    <p class="input-label-top">Nombre</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="subActivity.nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-4">
                    <p class="input-label-top">Duración (días)</p>
                    <ValidationProvider v-slot="{errors}" name="duracion" rules="required|numeric">
                        <el-input-number :controls="false" v-model="subActivity.duracion_dias" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
            <div class="row m-3 f-15">
                <div v-if="!checkPredecesora" class="col-3 my-auto">
                    <p class="input-label-top">Día inicio</p>
                    <ValidationProvider v-slot="{errors}" name="dia_inicio" rules="required|numeric">
                        <el-input-number :controls="false" v-model="subActivity.dia_inicio" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-4 my-auto pt-3">
                    <el-checkbox v-model="checkPredecesora">Actividad predecesora</el-checkbox>
                </div>
                <div v-if="checkPredecesora" class="col-3 my-auto">
                    <p class="input-label-top">Actividad predecesora</p>
                    <ValidationProvider v-slot="{errors}" name="predecesora" rules="required|numeric">
                        <el-select v-model="subActivity.id_actividad_predecesora" placeholder="Select">
                            <el-option v-for="(predecesora, idxPre) in select_actividad_predecesora" 
                                :key="idxPre"
                                :label="predecesora.nombre || predecesora.actividad"
                                :value="predecesora.id">
                            </el-option>
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
            <div class="row m-3 mb-4 f-15">
                <div class="col-8">
                    <p class="input-label-top">Descripción</p>
                    <ValidationProvider v-slot="{errors}" name="descripcion" rules="required">
                        <el-input v-model="subActivity.descripcion" type="textarea" :autosize="{ minRows: 4 }" class="w-100" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-4 my-auto">
                    <ValidationProvider v-slot="{errors}" name="color" rules="required">
                        <chrome-picker v-model="subActivity.color" ref="colorPicker" class="w-100" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { CalendarSubactivityVOFactory } from '~/domain/value_objects/configurar/obras/calendario_general/factories/CalendarSubactivityVOFactory'
import { Chrome } from 'vue-color'
import { mapGetters } from 'vuex'

export default {
    components:{
        'chrome-picker': Chrome
    },
    data(){
        return {
            subActivity: CalendarSubactivityVOFactory.instantiateEmpty(),
            textBtnAdicional: 'Crear',
            id_actividad: '',
            editando: false,
            titulo: '',
            checkPredecesora: false,
        }
    },
    computed: {
        ...mapGetters({
            select_actividad_predecesora: 'selects/selects/select_actividad_predecesora'
        })
    },
    watch:{
        subActivity: {
            handler(){
                this.subActivity.color = this.$refs.colorPicker.val.hex
            },
            deep: true
        }
    },
    mounted(){
        if (this.$route.name === 'configurar.obras.calendarios.talleres') {
            this.titulo = 'Crear actividad'
        }
    },
    methods: {
        toggle(subactividad){
            if(subactividad){
                this.accionEditar(subactividad)
            } else {
                this.accionCrear()
            }
            this.$refs.modalAddSubactivity.toggle()
        },
        accionEditar(subactividad){
            this.id_actividad = subactividad.id
            this.subActivity = {...subactividad}
            this.$refs.colorPicker.val.hex = subactividad.color
            this.subActivity.id = subactividad.id
            this.checkPredecesora = this.subActivity.id_actividad_predecesora != null
            this.titulo = "Editar actividad"
            this.textBtnAdicional = 'Editar'
            this.editando = true
        },
        accionCrear(){
            this.checkPredecesora = false
            this.$refs.subactivityValidator.reset()
            this.$refs.colorPicker.val.hex = '#000'
            this.titulo = "Crear actividad"
            this.textBtnAdicional = 'Crear'
            this.subActivity.id = ''
            this.cleanFields()
            this.editando = false
        },
        async add(){
            const valid = await this.$refs.subactivityValidator.validate() 
            if (!this.checkPredecesora) this.subActivity.id_actividad_predecesora = null
            if(valid){
                if(this.editando){
                    this.$emit('editSubactivity', this.subActivity)
                } else {
                    this.$emit('addSubactivity', this.subActivity)
                }
                this.$refs.modalAddSubactivity.toggle()
            }
        },
        cleanFields(){
            this.subActivity = CalendarSubactivityVOFactory.instantiateEmpty()
        }
    }
}
</script>