<template>
    <modal ref="modalAddActivity" titulo="Crear actividad" adicional="Crear" @adicional="add">
        <ValidationObserver ref="addActivityCalendar">
                <div class="row m-3 f-15">
                    <div class="col-12">
                        <p class="input-label-top">Nombre</p>
                        <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                            <el-input v-model="calendarActivity.nombre" class="w-100" size="small" ref="activityName" />
                            <vee-error :text="errors[0]" />
                        </ValidationProvider>
                    </div>
                </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { CalendarActivityFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/CalendarActivityFactory'
export default {
    data(){
        return {
            calendarActivity: CalendarActivityFactory.instantiateEmpty(),
        }
    },
    watch:{
        calendarActivity(val){
            this.$refs.activityName.focus();
        }
    },
    methods: {
        toggle(activity){
            if(activity){
                this.calendarActivity = activity
            }
            this.$refs.addActivityCalendar.reset()
            this.$refs.modalAddActivity.toggle()
            setTimeout(() => this.$refs.activityName.focus(), 800);
        },
        add(){
            const valid = this.$refs.addActivityCalendar.validate()
            if (valid) {
                this.$emit('addActivity', this.calendarActivity)
            }
            this.calendarActivity = CalendarActivityFactory.instantiateEmpty() 
            this.toggle()
        },
    }
}
</script>