var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalAddSubactivity",attrs:{"titulo":_vm.titulo,"tamano":"modal-lg","adicional":_vm.textBtnAdicional},on:{"adicional":_vm.add}},[_c('ValidationObserver',{ref:"subactivityValidator"},[_c('div',{staticClass:"row m-3 f-15"},[_c('div',{staticClass:"col-8"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.subActivity.nombre),callback:function ($$v) {_vm.$set(_vm.subActivity, "nombre", $$v)},expression:"subActivity.nombre"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Duración (días)")]),_c('ValidationProvider',{attrs:{"name":"duracion","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input-number',{staticClass:"w-100",attrs:{"controls":false,"size":"small"},model:{value:(_vm.subActivity.duracion_dias),callback:function ($$v) {_vm.$set(_vm.subActivity, "duracion_dias", $$v)},expression:"subActivity.duracion_dias"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)]),_c('div',{staticClass:"row m-3 f-15"},[(!_vm.checkPredecesora)?_c('div',{staticClass:"col-3 my-auto"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Día inicio")]),_c('ValidationProvider',{attrs:{"name":"dia_inicio","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input-number',{staticClass:"w-100",attrs:{"controls":false,"size":"small"},model:{value:(_vm.subActivity.dia_inicio),callback:function ($$v) {_vm.$set(_vm.subActivity, "dia_inicio", $$v)},expression:"subActivity.dia_inicio"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,false,1148980965)})],1):_vm._e(),_c('div',{staticClass:"col-4 my-auto pt-3"},[_c('el-checkbox',{model:{value:(_vm.checkPredecesora),callback:function ($$v) {_vm.checkPredecesora=$$v},expression:"checkPredecesora"}},[_vm._v("Actividad predecesora")])],1),(_vm.checkPredecesora)?_c('div',{staticClass:"col-3 my-auto"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Actividad predecesora")]),_c('ValidationProvider',{attrs:{"name":"predecesora","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.subActivity.id_actividad_predecesora),callback:function ($$v) {_vm.$set(_vm.subActivity, "id_actividad_predecesora", $$v)},expression:"subActivity.id_actividad_predecesora"}},_vm._l((_vm.select_actividad_predecesora),function(predecesora,idxPre){return _c('el-option',{key:idxPre,attrs:{"label":predecesora.nombre || predecesora.actividad,"value":predecesora.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,false,136749751)})],1):_vm._e()]),_c('div',{staticClass:"row m-3 mb-4 f-15"},[_c('div',{staticClass:"col-8"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Descripción")]),_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","autosize":{ minRows: 4 }},model:{value:(_vm.subActivity.descripcion),callback:function ($$v) {_vm.$set(_vm.subActivity, "descripcion", $$v)},expression:"subActivity.descripcion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-4 my-auto"},[_c('ValidationProvider',{attrs:{"name":"color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('chrome-picker',{ref:"colorPicker",staticClass:"w-100",model:{value:(_vm.subActivity.color),callback:function ($$v) {_vm.$set(_vm.subActivity, "color", $$v)},expression:"subActivity.color"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }