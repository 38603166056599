<template>
    <section class="config-obras-calendario-general">
        <div class="row">
            <div class="col-10 col-lg-10">
                <!-- title + addCalendar Option -->
                <titulo-divisor titulo="Calendarios predeterminados">
                    <el-tooltip content="Crear calendario" effect="light" visible-arrow>
                        <div class="bg-general br-4 wh-34 d-middle-center cr-pointer" @click="createNewCalendar">
                            <i class="icon-calendar-plus text-white f-20" />
                        </div>
                    </el-tooltip>
                </titulo-divisor>
                <section v-for="calendario in calendariosGenerales" :key="calendario.id" class="mb-4">
                    <!-- calendars list -->
                    <div class="row">
                        <div class="col-10 my-auto">
                            <div class="bg-general text-white br-4 px-2 d-flex py-1 justify-content-between">
                                <div class="d-middle">
                                    <i class="icon-calendar-month-outline" />
                                    <p class="f-12">{{ calendario.nombre }}</p>
                                </div>
                                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                    <template #content>
                                        <p class="p-2 cr-pointer item" @click="editCalendar(calendario)">Editar</p>
                                        <p class="p-2 cr-pointer item" @click="deleteCalendar(calendario.id)">Eliminar</p>
                                    </template>
                                    <i class="icon-dots-vertical cr-pointer f-20" />
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="col-1 px-0">
                            <el-popover v-model="visibleAddNewGroup" placement="bottom-end" width="360" trigger="manual" class="px-0">
                                <div class="row mx-0 justify-content-center f-600 text-general">
                                    Aregar grupo de tareas
                                </div>
                                <hr />
                                <div class="row mx-0 justify-content-center my-2">
                                    <div class="col-10 my-2">
                                        <p class="input-label-top">Nombre del grupo</p>
                                        <el-input v-model="calendario.nombre" class="w-100" size="small" />
                                    </div>
                                </div>
                                <hr />
                                <div class="text-right m-0">
                                    <button class="btn btn-cerrar f-12" @click="visibleAddNewGroup = !visibleAddNewGroup">Cerrar</button>
                                    <button class="btn btn-crear f-12 ml-2" @click="addNewGroup">Agregar</button>
                                </div>
                                <el-tooltip slot="reference" content="Agregar grupo tareas" effect="light" placement="right" visible-arrow>
                                    <i class="icon-plus-box f-25 text-general cr-pointer" @click="addActivity(calendario)" />
                                </el-tooltip>
                            </el-popover>
                        </div>
                    </div>
                    <draggable tag="div" :list="calendario.actividades" class="row" handle=".handle" @change="updatePositionActivity($event)">
                        <div v-for="(actividad,index) in calendario.actividades" :key="index" class="col-10 mb-4 mt-3">
                            <div class="row mx-0 border bg-f5">
                                <div class="col-6 my-auto">
                                    <div class="d-middle">
                                        <i class="icon-menu handle mr-3" />
                                        <p class="f-12"> {{ actividad.nombre }} </p>
                                    </div>
                                </div>
                                <div class="col-1 px-1">
                                    <i class="icon-ray-start-arrow f-20" />
                                </div>
                                <div class="col-1 px-1">
                                    <i class="icon-clock-outline f-20" />
                                </div>
                                <div class="col-auto ml-auto">
                                    <div class="d-flex icon-option">
                                        <i class="icon-plus-box f-20 cr-pointer" @click="addSubactivity(actividad.id, calendario.id)" />
                                        <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                            <template #content>
                                                <p class="p-2 cr-pointer item" @click="editActivityCalendar(actividad)">Editar</p>
                                                <p class="p-2 cr-pointer item" @click="deleteGroup(actividad.id, calendario.id)">Eliminar</p>
                                            </template>
                                            <i class="icon-dots-vertical cr-pointer f-20" />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <draggable tag="div" :list="actividad.actividades" class="" handle=".handle" @change="updatePositionActivityChildrens($event)">
                                <div v-for="(subactividad,idx) in actividad.actividades" :key="idx" class="row mx-0 border">
                                    <div class="col-6 my-auto">
                                        <div class="d-middle" style="padding-left: 36px;">
                                            <i class="icon-menu handle" />
                                            <i class="icon-circle-fill mr-1" :style="`color: ${subactividad.color}`" />
                                            <el-tooltip placement="bottom-start" effect="light" visible-arrow>
                                                <template #content>
                                                    <p class="f-12 terxt-5d f-600 mb-1"> {{ subactividad.nombre }} </p>
                                                    <p class="f-12 terxt-5d lh-15" style="max-width: 340px"> {{ subactividad.descripcion ? subactividad.descripcion : 'Sin descripción' }} </p>
                                                </template>
                                                <p class="f-12"> {{ subactividad.nombre }} </p>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="col-1 f-12 px-1 my-auto">
                                        Día {{ subactividad.dia_inicio }}
                                    </div>
                                    <div class="col-1 f-12 px-1 my-auto">
                                        {{ subactividad.duracion_dias }} Días
                                    </div>
                                    <div class="col-auto ml-auto">
                                        <div class="d-flex icon-option">
                                            <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                                <template #content>
                                                    <p class="p-2 cr-pointer item" @click="editSubactivity(subactividad, calendario.id)">Editar</p>
                                                    <p class="p-2 cr-pointer item" @click="deleteSubactivity(subactividad.id, actividad.id, calendario.id)">Eliminar</p>
                                                </template>
                                                <i class="icon-dots-vertical cr-pointer f-20" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </draggable>
                </section>
            </div>
        </div>
        <!-- partials -->
        <modal-eliminar ref="deleteCalendar" title="Eliminar calendario" mensaje="¿Está seguro que quiere eliminar este calendario?" @delete="handleDeleteCalendar"/>
        <modal-eliminar ref="deleteGroup" title="Eliminar grupo de actividades" mensaje="¿Está seguro que quiere eliminar este grupo?" @delete="handleDeleteActivity" />
        <modal-eliminar ref="deleteSubactivity" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?"  @delete="handleDeleteSubactivity"/>
        <modal-crear-calendario ref="createCalendar" />
        <modal-edit-calendar ref="addCalendar" @editGeneralCalendar="handleEditCalendar" />
        <modal-add-subactivity ref="addSubactivity" @addSubactivity="handleAddSubactivity" @editSubactivity="handleEditSubactivity"/>
        <modal-add-activity ref="addActivity" @addActivity="handleAddActivity"/>
        <modal-edit-activity-calendar-general ref="editarActividadGeneral" @editActivity="handleEditActivity" />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GeneralCalendarFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/GeneralCalendarFactory'
import modalAddActivity from './partials/modalAddActivity.vue'
import modalAddSubactivity from './partials/modalAddSubactivity.vue'
export default {
    components:{
        modalAddSubactivity,
        modalEditCalendar: () => import('./partials/modalEditCalendar.vue'),
        modalCrearCalendario: () => import('./partials/modalCrearCalendario.vue'),
        modalEditActivityCalendarGeneral: () => import('./partials/modalEditActivityCalendarGeneral.vue'),
        modalAddActivity
    },
    data(){
        return{
            generalCalendar: GeneralCalendarFactory.instantiateEmpty(),
            subactivityToDelete: {},
            calendarioToDelete: null,
            activityToDelete: {},
            activityToCreate: {},
            toCreate: {},
            toDelete: {},
            visibleCreateCalendar: false,
            visibleAddNewGroup: false,
            visibleEditGroup: false,
            valueSelect: '',
        }
    },
    computed:{ 
        ...mapGetters({
            calendariosGenerales: 'configurar/obras/calendario_general/calendario_general',
        }),
    },
    methods:{
        ...mapActions({
            obtenerCalendariosGenerales: 'configurar/obras/calendario_general/Action_get_calendario_general',
            crearCalendarioGeneral: 'configurar/obras/calendario_general/Action_create_calendario_general',
            crearActividadCalendario: 'configurar/obras/calendario_general/Action_create_calendario_general_actividad',
            crearSubactividadCalendario: 'configurar/obras/calendario_general/Action_create_calendario_general_subactividad',
            eliminarCalendarioGeneral: 'configurar/obras/calendario_general/Action_delete_calendario_general',
            eliminarActividadCalendario: 'configurar/obras/calendario_general/Action_delete_calendario_general_actividad',
            eliminarSubactividadCalendario: 'configurar/obras/calendario_general/Action_delete_calendario_general_subactividad',
            editarCalendario: 'configurar/obras/calendario_general/Action_edit_calendario_general',
            editarActividad: 'configurar/obras/calendario_general/Action_edit_calendario_general_actividad',
            editarSubactividad: 'configurar/obras/calendario_general/Action_edit_calendario_general_subactividad',
            Action_update_position_calendar_activity_children: 'configurar/obras/calendario_general/Action_update_position_calendar_activity_children',
            Action_update_position_calendar_activity: 'configurar/obras/calendario_general/Action_update_position_calendar_activity',
            Action_get_actividad_predecesora: 'selects/selects/Action_get_actividad_predecesora',
        }),

        createNewCalendar(){
            this.$refs.createCalendar.toggle()
        },
        editCalendar(calendar){
            this.$refs.addCalendar.toggle(calendar)
        },
        deleteCalendar(id){
            this.toDelete = id
            this.$refs.deleteCalendar.toggle()
        },
        addNewGroup(){
            
        },
        async editOnGroup(actividad){
            await this.editarActividad({
                id: actividad.id,
                payload: actividad
            })
            this.$refs[`editarGrupo-${id}`][0].click()
        },
        deleteGroup(id_actividad, id_calendario){
            this.calendarioToDelete = id_calendario
            this.activityToDelete = id_actividad
            this.$refs.deleteGroup.toggle()
        },
        deleteSubactivity(id_subactividad, id_actividad, id_calendario){
            this.calendarioToDelete = id_calendario
            this.activityToDelete = id_actividad
            this.subactivityToDelete = id_subactividad
            this.$refs.deleteSubactivity.toggle()
        },
        async addSubactivity(id_actividad, id_calendario){
            await this.Action_get_actividad_predecesora({id_calendario})
            this.calendarioToCreate = id_calendario
            this.activityToCreate = id_actividad
            this.$refs.addSubactivity.toggle()
        },
        async editSubactivity(subactivity, id_calendario){
            await this.Action_get_actividad_predecesora({id_calendario})
            this.$refs.addSubactivity.toggle(subactivity)
        },
        addActivity(calendario){
            this.toCreate = calendario.id
            this.$refs.addActivity.toggle()
        },
        async handleEditCalendar(calendar){
            await this.editarCalendario({
                payload: calendar,
                id: calendar.id
            })
        },
        async handleAddActivity(actividad){
            await this.crearActividadCalendario({
                ...actividad,
                id_configuracion_calendarios: this.toCreate
            })
        },  
        async handleAddSubactivity(subactividad){
            await this.crearSubactividadCalendario({
                ...subactividad,
                id_configuracion_calendarios: this.calendarioToCreate,
                id_configuracion_calendarios_actividades: this.activityToCreate,
            })
        },
        async handleDeleteCalendar(){
            await this.eliminarCalendarioGeneral({id: this.toDelete})
        },
        async handleDeleteActivity(){
            await this.eliminarActividadCalendario({
                id_configuracion_calendarios: this.calendarioToDelete,
                id_configuracion_calendarios_actividades: this.activityToDelete
            })
        },
        async handleDeleteSubactivity(){
            await this.eliminarSubactividadCalendario({
                id: this.subactivityToDelete,
                id_configuracion_calendarios: this.calendarioToDelete,
                id_configuracion_calendarios_actividades: this.activityToDelete,
            })
            await this.obtenerCalendariosGenerales()
        },
        async handleEditSubactivity(subactividad){
            await this.editarSubactividad({
                id: subactividad.id,
                payload: subactividad,
                id_configuracion_calendarios: this.calendarioToDelete,
                id_configuracion_calendarios_actividades: this.activityToDelete
            })
            await this.obtenerCalendariosGenerales()
        },
        editActivityCalendar(calendario){
            this.$refs.editarActividadGeneral.toggle(calendario)
        },
        async handleEditActivity(actividad){
            await this.editarActividad({
                id: actividad.id,
                payload: actividad
            })
            await this.obtenerCalendariosGenerales()
        },
        // updatePositionActivity(e){
        //     console.log(e);
        // },
        async updatePositionActivityChildrens(e){
            let obj = {
                "id_configuracion_calendarios_actividades_actividades": e.moved.element.id,
                "posicion": e.moved.newIndex + 1,
            }
            await this.Action_update_position_calendar_activity_children(obj)
            await this.obtenerCalendariosGenerales()
        },
        async updatePositionActivity(e){
            let obj = {
                "id_configuracion_calendarios_actividades": e.moved.element.id,
                "posicion": e.moved.newIndex + 1,
            }
            await this.Action_update_position_calendar_activity(obj)
            await this.obtenerCalendariosGenerales()
        },
    },
    async mounted(){
        await this.obtenerCalendariosGenerales()
    }

}
</script>

<style lang="scss" scoped>
.config-obras-calendario-general{
    .handle{
        cursor: grabbing;
    }
    .icon-ray-start-arrow, .icon-clock-outline{
        &::before{
            margin-left: 0px !important;
        }
    }
}
</style>